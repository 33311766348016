.container {
  /* height: 595px; */
  width: 100%;
  /* background: url("../Pages/assets/homelogo.svg") no-repeat center center /
    cover; */
  height: auto;
  /* border: 1px solid black; */
  margin-top: 1rem;
}
.container > img {
  display: flex;
  align-content: center;
  -webkit-box-shadow: 0px 106px 45px -60px rgba(26, 22, 255, 0.6);
  -moz-box-shadow: 0px 106px 45px -60px rgba(26, 22, 255, 0.6);
  box-shadow: 0px 106px 45px -60px rgba(26, 22, 255, 0.6);
}

.scroll-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 55px;
  left: 50%;
  transform: translateX(-50%);
  color: #000;
  text-align: center;
  cursor: pointer;
  z-index: 1000;
  margin-top: 1rem;
}

.arrow {
  width: 24px;
  height: 24px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  transform: rotate(-45deg);
  margin-bottom: 20px;
  animation: bounce 2s infinite;
}

.scroll-text {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-right: 24px;
  font-family: Mulish, sans-serif;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(-50%) translateY(0) rotate(-45deg);
  }
  40% {
    transform: translateX(-50%) translateY(-10px) rotate(-45deg);
  }
  60% {
    transform: translateX(-50%) translateY(-5px) rotate(-45deg);
  }
}

.next-section {
  height: auto;
  background-color: #f0f0f0;
  padding: 20px;
  margin-top: 20px;
}
