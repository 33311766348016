.about-us {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem;
  gap: 2rem;
  /* margin-bottom: 1%; */
}

.card1 {
  flex: 1;
  max-width: 45%;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-img {
  width: 100%;
  max-width: 500px;
  height: 90vh; /* height:80 */
  margin-left: 7rem;
}

.accordion_container {
  flex: 1;
  max-width: 45%;
  width: 100%;
}

.accordion {
  width: 100%;
}

.accordion-item {
  padding: 15px 0;
}

.accordion-title {
  width: 80%;
  display: flex;
  align-items: center; /* Align items in a single line */
  cursor: pointer;
  transition: color 0.3s, font-weight 0.3s;
  justify-content: space-between;
  gap: 0.5rem; /* Adjust the gap between the title and plus button */
}

.accordion-title h2 {
  margin: 0;
  font-size: 2rem;
  font-weight: normal;
  background-color: #000000;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.accordion-title span {
  font-size: 2.8rem;
  color: #333;
  margin-right: 0px; /* Remove extra space between the plus button and text */
}

.number_title {
  display: flex;
  align-items: center;
  gap: 1.5rem; /* Control spacing between number and title */
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  font-size: 1rem;
  color: #666;
  margin-top: 10px;
  text-align: justify;
  margin-right:20%;
}

.accordion-content.show {
  max-height: 200px;
}

.number {
  font-size: 3rem;
  font-weight: bold;
  background-color: #000000;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 10px;
  text-align: center;
}

.active .number,
.active h2 {
  background-color: #5956e9;
  font-weight: bold;
  
}
