.card {
  height: 285px;
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left; 
  color: #333;
  width: 300px;
  margin: 20px;

  /* transition: transform 0.2s; Add transition for hover effect */
}

.card:hover {
  /* transform: scale(1.05); Scale up on hover */
}

.icon {
  width: 40px; 
  height: 40px;
  margin-right: 10px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: c;
  margin-bottom: 10px;
}

h3 {
  margin: 0;  
  font-size: 1.4rem;
  font: Mulish;
}

p {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
}