.swiper-wrapper {
  margin-top: 5%;
  display: flex;
  align-items: center !important;
  justify-content: space-between;
  gap: 35px;
  margin-top: 1.5rem;
}

.swiper-wrapper .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-wrapper .swiper-slide img {
  width: 100%;
  height: auto;
  max-height: 345px;
  border-radius: 20px;
}

@media screen and (max-width: 1200px) {
  .swiper-wrapper .swiper-slide img {
    width: 70%;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .swiper-wrapper .swiper-slide img {
    width: 90%;
    height: auto;
  }
}

.bg_img {
  display: flex;
  margin-top: 15%;
  height: 100vh;
  background-image: url("../Pages/assets/ourteam.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  position: relative;
}

.swiper-button-prev,
.swiper-button-next {
  width: 50px;
  height: 50px;
  color: #000000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  border: 1px solid black;
}

.swiper-button-prev,
.swiper-button-next {
  background-color: transparent;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 20px;
}

.see-all-btn {
  position: absolute;
  top: 80px;
  right: 80px;
  background-color: #6a63f6;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  z-index: 3;
}

.see-all-btn:hover {
  background-color: #5a54d4;
}

@media screen and (max-width: 768px) {
  .see-all-btn {
    top: 10px;
    right: 10px;
    padding: 8px 16px;
    font-size: 12px;
  }
}

.team-modal-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.team-modal-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 200px;
}

.team-modal-img {
  width: 100%;
  height: auto;
  max-height: 230px;
  object-fit: cover;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .team-modal-grid {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.5);
}
