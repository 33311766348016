.section-wrapper {
  position: relative;
  background: url('../Pages/assets/medialogo.svg');
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  padding: 2% 3%;
  margin-bottom: 10%;
  margin: 3%;
}

.cards-wrapper {
  margin-top: 10%;
  width: 105%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2%;
  row-gap: 40px;
}

.card {
  width: 28%;
  height: auto;
  background: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  text-align: left;
  padding: 0;
}

.card-inner {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


.card:nth-child(-n+3) .card-inner {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, #FFFFFF 45.5%);
}


.card:nth-child(n+4) .card-inner {
  background: rgba(255, 255, 255, 0.5);

}

.card img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 10px; 
}

.card-content {
  padding-top: 10px;
}

.section-wrapper h3 {
  font-size: 1.5em;
  color: #333;
  justify-content: left;
  padding-left: 4%;
}

.card-content p {
  margin: 0;
  font-size: 1.1em;
  color: #666;
  padding-left: 4%;
}
