body {
  margin: 0;
  font-family: Mullish, sans-serif;
}

.leader-desk {
  margin-top: 2%;
  background: url("../Pages/assets/leaderslogo.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  text-align: center;
  width: 100%;
}

.leader-desk-title {
  margin-right: 55%;
  font-size: 2.5rem;
}

.leader-desk-subtitle {
  margin-right: 50%;
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.leader-cards {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}

.leader-card {
  border-radius: 30px;
  width: 30%;
  overflow: hidden;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  position: relative;
}

.leader-more-button:hover {
  color: #ccc;
}

.leader-info {
  /* position: relative; */
  /* bottom: 419px; */
  height: auto;
  width: 95%;
  margin: 1%;
  background: linear-gradient(
    to left,
    rgba(89, 86, 233, 0.8) 00%,
    rgba(61, 37, 131, 0.8) 100%
  );
  padding: 0 10px;
  border-radius: 0 0 20px 20px;
  text-align: left;
  z-index: 1;
  border-radius: 50px;
}

/* .leader-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
} */

/* .leader-icon {
  width: 13%;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 5px;
} */

/* .leader-name {
  font-size: 1.2rem;
  margin-top: 10px;
  color: rgba(255, 255, 255, 1);
  padding-left: 4px;
} */

.leader-title {
  color: rgba(255, 255, 255, 1);
}

.leader-quote-text.expanded {
  white-space: normal;
}

.leader-more-button.expanded {
  color: red;
}

/* .leader-title-md-ceo {
  font-size: 0.7rem;
  font-weight: bold;
  padding-right: 50%;
  margin: 5px 0;
} */

/* .leader-title-director {
  font-size: 0.7rem;
  font-weight: bold;
  padding-right: 78%;
  margin: 5px 0;
} */

.leader-container {
  position: relative;
  width: 30%; /* Adjust the width as needed */
  margin-top: 20%;
}

.leader-card-img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 40px;
}

.leader-info-overlay {
  position: absolute;
  top: 2%;
  width: 95%;
  left: 3%;
  padding: 10px;
  color: white;
  background: linear-gradient(
    to left,
    rgba(89, 86, 233, 0.8) 0%,
    rgba(61, 37, 131, 0.8) 100%
  );
  border-radius: 50px;
}

.leader-card-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  /* padding: 10px; */
  color: white;
}

.leader-header {
  display: flex;
  align-items: center;
  border-radius: 50px;
}

.leader-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.leader-name {
  margin: 2px;
  font-size: 1.2rem;
}

.leader-title-md-ceo,
.leader-title-director {
  margin: 2px;
  font-size: 0.7rem;
  font-weight: bold;
  justify-content: left;
}

.leader-quote-overlay {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    to left,
    rgba(89, 86, 233, 0.8) 0%,
    rgba(61, 37, 131, 0.8) 100%
  );
  border-radius: 20px;
  padding: 15px;
  margin: 1%;
}

.leader-quote-text {
  margin: 0;
  font-size: 0.8rem;
  text-align: justify;
}

.leader-more-button {
  display: flex;
  position: absolute;
  bottom: 4px;
  right: 10px;
  background: transparent;
  border: 1px solid #ffffff;
  color: white;
  font-size: 1.6rem;
  cursor: pointer;
  width: 30px;
  height: auto;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}
.leader-more-button,
.leader-quote-overlay {
  margin-bottom: 1.5rem;
}

/* .leader-quote-overlay {
  position: absolute;
  bottom: 18%;
  left: 50%;
  transform: translateX(-50%);
  width: 85%;
  background: linear-gradient(
    to left,
    rgba(89, 86, 233, 0.8) 0%,
    rgba(61, 37, 131, 0.8) 100%
  );
  padding: 10px 5px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
} */

/* .leader-quote-text {
  font-family: Mulish, sans-serif;

  font-size: 0.74rem;
  color: #ffffff;
  margin: 0;
  flex: 1;
  padding-right: 20px;
  line-height: 24px;
  text-align: left;
} */

/* .leader-more-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: transparent;
  border: 1px solid #ffffff;
  color: white;
  font-size: 1.6rem;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
} */
