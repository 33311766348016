.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 40px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.scroll-button2 {
    background-color: #5956e9;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px; 
    cursor: pointer;
    font-size: 20px; 
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, transform 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scroll-button2:hover {
    background-color: darkviolet;
    transform: scale(1.1); 
}

.scroll-text2 {
    font-size: 14px;
    color: #5956e9;
}
