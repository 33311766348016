* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Mulish, sans-serif;
}

.Navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 50px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo1 img {
  height: 60px;
}

.nav-links {
  width: 70%;
}

.nav-links > ul {
  display: flex;
  justify-content: space-between;
}

.nav-links li {
  padding: 10px 0;
  background: transparent;
}

.nav-links a {
  text-decoration: none;
  color: black;
  font-size: 16px;
  position: relative;
  padding: 5px 0;
}

.nav-links a:hover {
  background-image: linear-gradient(to right, #7445ff, #0084ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nav-links a:active {
  font: linear-gradient(to right, #7445ff, #0000ff);
  box-shadow: 0px 4px 8px 0px #6037da26;
}

.nav-links a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -4px;
  background: linear-gradient(to right, #7445ff, #0084ff);
  visibility: hidden;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out, visibility 0.3s;
}

.nav-links a:hover::before,
.nav-links a.active::before {
  visibility: visible;
  transform: scaleX(1);
}

.nav-links a.active {
  color: #7445ff;
  background-image: linear-gradient(to right, #7445ff, #0084ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact-button {
  padding: 15px 32px;
  border-radius: 62px;
  background-color: #5956e9;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  text-align: center;
  text-wrap: nowrap;
}

.contact-button:hover {
  background-color: darkviolet;
}

.contact-button.active {
  background-color: #7445ff;
}
