.focus-area {
  display: flex ;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 695px;

  background: url("../Pages/assets/focusarea.svg") no-repeat center center /
    cover;
  border-radius: 15px;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 106px 45px -60px rgba(26, 22, 255, 0.6);
  -moz-box-shadow: 0px 106px 45px -60px rgba(26, 22, 255, 0.6);
  box-shadow: 0px 106px 45px -60px rgba(26, 22, 255, 0.6);
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 9%;
}

.focus-area h2 {
  font-size: 2em;
  margin-bottom: 10px;
}

.focus-area p {
  font-size: 0.8em;
  margin-bottom: 1px;
}

.focus-cards {
  display: flex;
  margin-top: 30%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
}

h3 {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
}
