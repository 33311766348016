.team-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.team-modal-header h2 {
  font-size: 27px;
  font-weight: bold;
  color: #3a3a3a;
}

.close-btn {
  color: #3a3a3a !important;
  border: 2px solid #3a3a3a !important;
  border-radius: 50% !important;
  padding: 0 !important;
}

.MuiBox-root {
  background-color: #ffffff80 !important;
  backdrop-filter: blur(5px);
  border-radius: 50px !important;
  padding: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  max-height: 90vh;
  height: 90vh;
  overflow: auto;
  scrollbar-width: none !important;
}

.team-modal-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.team-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.team-modal-header h2 {
  font-size: 27px;
  font-weight: bold;
  color: #3a3a3a;
}

.filter-btn-group {
  display: flex;
  justify-content: center;
  gap: 5rem;
  align-items: center;
}

.filter-btn {
  padding: 5px 20px;
  font-size: 13px;
  font-weight: bolder;
  color: #5d5d5d;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
}

.filter-btn.active {
  background-color: #6a63f6;
  color: white;
  border: 1px solid #6a63f6;
  box-shadow: 0px 4px 10px rgba(106, 99, 246, 0.5); /* Active button shadow */
}

@media screen and (max-width: 768px) {
  .filter-btn {
    padding: 8px 16px;
    font-size: 12px;
  }

  .MuiBox-root {
    width: 90%;
  }
}
