.accomplishments {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 3%;
  margin-top: 19%;
  padding: 4%;
  text-align: center;
  border-radius: 20px;
  -webkit-box-shadow: 0px 106px 45px -60px rgba(26, 22, 255, 0.6);
  -moz-box-shadow: 0px 106px 45px -60px rgba(26, 22, 255, 0.6);
  box-shadow: 0px 106px 45px -60px rgba(26, 22, 255, 0.6);
}

.font {
  margin-bottom: 30px;
}

.font h2 {
  margin: 0;
  font-size: 2.5em;
  font-weight: bold;
}

.font p {
  margin: 10px 0;
  font-size: 1.2em;
}

.cards11 {
  display: flex;
  gap: 130px;
  justify-content: space-evenly;
  position: relative;
  top: 150px;
  z-index: 2;
}

.card44 {
  background: white;
  border-radius: 15px;
  padding: 50px 20px;
  width: 250px;
  text-align: center;
  height: 300px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  position: relative;
}
.text {
  padding: 20px;
  font-weight: bolder;
  text-align: center;
  font-size: 1.3em;
  color: #333;
  margin-top: 10px;
  line-height: 1.5;
}

.number66 {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 10px;
  color: #2b8df7;
  background-color: #add8e6;
  width: 36%;
  height: 50vh;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.card44:nth-child(2) .text {
  font-size: 1.419em;
}

.card44:nth-child(2) .number66 {
  background-color: #c5e8c4;
  color: #6eab6c;
}

.card44:nth-child(3) .number66 {
  background-color: #ffdce9;
  color: #dd7fa1;
}

.info-button:hover {
  background-color: #6966ff;
  color: #ffffff;
}

.flip-container {
  perspective: 1000px;
  position: relative;
}

.flipper {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.flipped .flipper {
  transform: rotateY(180deg);
}

.front,
.back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: top;
  flex-direction: column;
}

.front {
  background: white;
}

.back {
  background-color: #ffffff;
  color: white;
  transform: rotatey(180deg);
  text-align: center;
}

.back-content {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-top: 12px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.info-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #6966ff;
  color: white;
  border: 3px solid white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
}
