:root {
  --primary-color: #4361EE;
  --secondary-color:  #DCDCDC;
  --shadow-color: rgba(26,22,255,0.6);
  --text-color: white;
  --button-hover-color: #3651c4;
}

.event-header {
  position: relative;
  background: url('../Pages/assets/eventlogo.svg') no-repeat center top;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-size: contain;
  margin: 3%;
  text-align: center;
  border-radius: 1%;
  padding-top: 10%; 
  margin-bottom: 10%;
  z-index: 1;
  overflow: visible;
}

.header-tabs {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 9%;
  left:74%;
  z-index: 2;
}

.tab:nth-child(2) {
  color: var(--secondary-text-color); 
}

.tab {
  background: var(--secondary-color);
  border: none;
  color: var(--text-color);
  padding: 8px 25px;
  margin: 0 10px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 12px;
}

.tab.active {
  background: var(--primary-color);
}

.event-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); 
  grid-gap: 10px; 
  padding-top: 2%;
  max-width: 100%;
  overflow: visible;
  z-index: 3;
}

.event-card-wrapper {
  display: flex;
  flex-direction: row; 
  align-items: center;
  position: relative;
  z-index: 4;
  margin-bottom: 30px;
}

.event-card {
  flex-direction: row;
  background: transparent;
  border-radius: 15px;
  padding: 20px;
  text-align: left;
  color: #333;
  max-width: 400px;
  height: auto;
  position: relative;
  border-radius: 1%;

}

.event-icon-wrapper {
  position: relative;
  width: 100%;
  height: 300px;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 10px;
  z-index: 4;
}

.event-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  position: absolute;
  top: 0;
  left: 0;
}

.event-icon-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
              linear-gradient(to bottom , rgba(255, 255, 255, 0), rgba(136, 136, 136, 1));
  mix-blend-mode: multiply;
  z-index: 1;
  pointer-events: none;
}

.tags {
  display: flex;
  position: absolute;
  top: 10px;
  left: 30px;
  right: 1px;
  gap: 10px;
}

.tag {
  background: #F6F6F6;
  color: #333;
  padding: 10px 30px;
  border-radius: 20px;
  font-size: 1rem;
  margin-top: 67%;
  z-index: 10;
  cursor: pointer;
}

.card-footer h3{
  font-family: Mulish;
  justify-content: left;
  margin-top: 10px;
  font-weight: bolder;
  font-size: 1.5rem;
}

.card-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 15px;
}

p {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
}

.completed-tag {
  position: absolute;
  top: 5%;
  right: 10%;
  background: #FFFFFFB2;
  color: #5956E9;
  padding: 5px 25px;
  border-radius: 20px;
  font-size: 13px; 
  text-align: center;
  z-index: 1;
  cursor: pointer;
}

.view-more-button {
  font-family: Mulish, sans-serif;
  color: rgb(0, 0, 0);
  background: transparent;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 1rem;
  position: absolute;
  bottom: 1px;
  left: 3%;
}

.event-card h3{
  margin-top: 3%;

  font-size: 1.5rem;
}

