.footer {
  text-align: center;
  background-color: transparent;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  margin-top: 8%;
}

.contact-info {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  margin: 50px 0;

}

.map-container {
  position: relative; 
  text-align: center;
}

.map-wrapper {
  width: 400px;
  height: 370px;
  overflow: visible;
  position: relative;
  left:20%;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); 
}

.map-wrapper iframe {
  border-radius: 12px;
  border: none;
  width: 100%;
  height: 100%;
  transform: scale(1.05);
  transform-origin: top left;
  position: absolute;
  top: -20px;
  left: -20px;
}

.map-overlay {
  display: flex;
  align-items: center; 
  justify-content:left;
  position: absolute;
  bottom: 15px;
  background-color: #5956E9;
  padding: 4px 11px;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
  left: 55%;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); 
}
.details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 10%;
  margin-top: 40px;
  gap: 20px;
}

.detail-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.icon {
  width: 30px;
  margin-right: 10px;
}

.detail-item h5 {
  margin-right: 16%;
  font-family: Mulish, sans-serif;
  font-size: 1.4rem;
}

.detail-item p {
  margin-left: 8px;
  padding: 5px;
  
}

.detail-item h3 {
  margin-right: 8px;
}

.map-text {
  margin-top: 10px;
  color: blue;
  cursor: pointer;
}



.pointer-icon {
  width: 18px;
  height: 18px;
  margin-right: 8px; 
}

.custom-map-text {
  color: white;
  font-size: 13px;
  cursor: pointer;
}